<template>
    <div class="CompanyJoinList">
        <!-- <div class="head padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">群名：</div>
                    <el-input v-model="company_name" placeholder="请输入账户名称或ID"></el-input>
                </div>
                <div class="searchdan">
                    <div class="title">群名称：</div>
                    <el-select v-model="company_id" filterable placeholder="请输入群名称" remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikesearch">
                        <el-option v-for="item in qunlist" :key="item.value" :label="item.company_name" :value="item.id"></el-option>
                    </el-select>
                </div>
                <div class="searchdan">
                    <div class="title">企业名称：</div>
                    <el-select v-model="corporate_account_id" placeholder="请输入企业名称" filterable remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikeqy">
                        <el-option v-for="item in qiyelist" :key="item.id" :label="item.corporate_account_name" :value="item.id"></el-option>
                    </el-select>
                </div>
                <div class="searchdan">
                    <div class="title">端口：</div>
                    <el-select v-model="account_port_id" placeholder="请输入端口" filterable remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikedk">
                        <el-option v-for="item in accountlist" :key="item.value" :label="item.advertiser_name" :value="item.id"></el-option>
                    </el-select>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div> -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">企业总览</div>
                <!-- <el-button type="primary" id="AddAccount" @click="addzhanghu($event)" class="yinyinga" icon="el-icon-plus">新增账户</el-button> -->
                <!-- <div class="lvtotal">总条数：{{lvtotal}} 条</div> -->
            </div>
            <el-table ref="filterTable" :data="CompanyJoinList" style="width: 100%">
                <el-table-column prop="company_name" label="群名" width="250px"></el-table-column>
                <el-table-column prop="agent_account" label="账户ID"></el-table-column>
                <el-table-column prop="agent_account_name" label="账户名称"></el-table-column>
                <el-table-column prop="advertiser_name" label="端口名称"></el-table-column>
                <el-table-column prop="corporate_account_name" label="企业名称"  width="230px"></el-table-column>
                <el-table-column prop="operating_name" label="运营官名称"></el-table-column>
                <el-table-column prop="is_operating_type" label="运营类型"></el-table-column>
                <!-- <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="ComAccount">编辑</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
            <!-- 分页 -->
            <div class="fenye" style="text-align:right;margin-top:20px">
                <el-button @click="onPage(paramPage-1)">上一页</el-button>
                <el-button @click="onPage(paramPage+1)">下一页</el-button>
                <!-- <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination> -->
            </div>
        </div>
    </div>
</template>
 
<script>
export default {
    data() {
        return {
            paramData:{},//表格参数
            shopid:'',//参数
            pickerTime:[],//日期参数
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页
            CompanyJoinList:[],//表格数据


            advertiser_name: "",//端口名称
            agent_account: "",//账户ID
            agent_account_name: "",//账户名称
            company_name: "",//群名
            corporate_account_name: "",//企业名称
            is_operating_type: "",//运营类型
            operating_name: ""//运营官名称





        };
    },

    mounted() {
        this.onDataList()
    },

    methods: {
        onPage(val){
            console.log(val)
            if(val>0){
                this.paramPage = val
                this.onDataList()
            }else{
                this.$message({
                    message: '您已经在第一页',
                    type: 'warning'
                });
            }
            
        },
        //商品搜索
        onSearch(){
            this.paramData = {
                // company_name: this.company_name,//群名
                // advertiser_name: this.advertiser_name,//端口名称
                // agent_account: this.agent_account,//账户ID
                // agent_account_name: this.agent_account_name,//账户名称
                // corporate_account_name: this.corporate_account_name,//企业名称
                // is_operating_type: this.is_operating_type,//运营类型
                // operating_name: this.operating_name//运营官名称
            }
            this.paramPage = 1
            this.onDataList()
        },
         // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.onDataList()
        },
        //数据列表
        onDataList(){
            this.CompanyJoinList = []
            var param = this.paramData
            param.page = this.paramPage
            this.$service.get(this.$api.getCompanyJoinList,param, (res)=> {
                if(res.code == '200'){
                    this.CompanyJoinList = res.data
                    this.lvtotal = res.data.total;
                }
            })
        },
    },

    

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>